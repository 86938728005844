import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

if (!new URLSearchParams(window.location.search).has('affiliate')) {
    window.location.href = 'https://www.google.com';
}

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);
