import {Box, useRadio} from "@chakra-ui/react";

function RadioCard(props) {
    const {getInputProps, getCheckboxProps} = useRadio(props)

    const input = getInputProps()
    const checkbox = getCheckboxProps()

    return (
        <Box as="label">
            <input {...input} />
            <Box
                {...checkbox}
                cursor="pointer"
                borderWidth="1px"
                borderRadius="md"
                boxShadow="md"
                _checked={{
                    bg: "#13b1c1",
                    color: "white",
                    borderColor: "#13b1c1"
                }}
                _focus={{
                    boxShadow: "outline",
                }}
                px={3}
                py={3}
            >
                {props.children}
            </Box>
        </Box>
    )
}

export default RadioCard;
